<template>
    <placeholder-panel>
        <h1 class="display-3 text-center">
            👥<small>?</small>
        </h1>
        <h5 class="text-center">
            Select a <strong>client</strong> for details
        </h5>
    </placeholder-panel>
</template>

<script>
    import PlaceholderPanel from "../PlaceholderPanel";
    export default {
        name: "VendorsPlaceholderPanel",
        components: {
            PlaceholderPanel,
        },
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    small {
        position: relative;
        bottom: -15px;
        left: 5px;
        font-weight: 700;
    }
</style>
