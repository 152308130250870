<template>
    <div class="d-none d-lg-flex rounded-lg card bg-light justify-content-center align-items-center shadow-sm h-100">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "PlaceholderPanel",
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
